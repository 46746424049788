<template>
  <div class="register">
    <TopNav />

    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">

          <div class="row">
            <div class="col-lg-6 p-0">
              <img src="../../assets/img/background-12.png" width="100%" />
            </div>

            <div class="custom-register-container-1 col-lg-6 p-4">
              <div class="row custom-register-container-2">
                <div class="col-lg-1">
                  <font-awesome-icon class="custom-register-icon" icon="fa-angle-left" @click="navigateAuthChoose()" />
                </div>
                <div class="col-lg-4">
                  <p class="custom-auth-subtitle m-0">OTP Verification</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-1"></div>

                <div class="col-lg-11">
                  <small class="text-black"> OTP code is sent to both email and phone number provided</small>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-12 p-0">
                      <b-form-input type="number" v-model="otp" placeholder="Enter OTP" class="custom-otp-input"></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <b-button size="sm" class="custom-otp-button btn-sm mt-3" @click="send()" :disabled="btnDisabled">
                    <b-spinner label="Spinning" v-if="btnIcon" small></b-spinner>
                    <span v-if="!btnIcon">{{btnText}}</span>                    
                  </b-button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-6">
                  <b-button class="custom-auth-signin mt-3" @click="verify()" :disabled="buttonDisabled">
                    <b-spinner class="custom-blue-spinner" label="Spinning" v-if="buttonIcon" small></b-spinner>
                    <span v-if="!buttonIcon">VERIFY OTP</span>                    
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="row custom-register-container-3 p-4 shadow">
            <div class="col-lg-6">
              <p class="text-black m-0">
                Making your life smarter and easier to manage your cash and life everyday, everywhere
              </p>
            </div>
          </div>

          <div class="row custom-auth-container-2 p-4 shadow">
            <div class="col-lg-6">
              <p class="custom-auth-subtitle m-0">
                IBS helps you enjoy the convenience of banking and access loans at the comfort of your home or at go!
              </p>
            </div>
            <div class="col-lg-6 px-4">
              <small class="text-black">
                If you are having issues in registration or login to Online Banking service, please contact our contact
                center or write to us.
              </small>

              <div class="row mt-4">
                <div class="col-6">
                  <small class="custom-auth-heading">Phone Number</small>
                </div>
                <div class="col-6">
                  <small class="custom-auth-heading">Email</small>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <small class="text-black">+254 729 333 444</small>
                </div>
                <div class="col-6">
                  <small class="text-black">info@ibsbank.so</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>
  
<script>
import { EventBus } from '@/main';
import api from "@/apis/api";
import { mapGetters, mapMutations } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'RegisterView',
  components: {
    SideNav,
    TopNav,
    Footer
  },

  data() {
    return {
      count: 40,
      btnIcon: false,
      btnDisabled: false,
      btnText: "SEND OTP",

      buttonIcon: false,
      buttonDisabled: false,

      otp: null
    }
  },

  created() {
    if(this.getNationalID == null) {
      this.$router.push("login")
    }
    else {
      this.send()
    }
  },

  computed: {
    ...mapGetters(['getNationalID'])
  },

  methods: {
    ...mapMutations([
      'setUserDetails',
      'setClientAccounts'
    ]),

    navigateAuthChoose() {
      this.$router.push("auth-choose")
    },

    send() {
      let that = this

      let data = {}
      data.idNumber = that.getNationalID

      that.btnIcon = true
      that.btnDisabled = true 

      api.sendOTP(data)
        .then((response) => {
          that.showSuccess("OPT sent successfully")
          that.otpCounter()

          that.btnIcon = false
        })
        .catch(function (error) {
          that.btnIcon = false
          this.btnDisabled = false
          that.showError(error.response.data.message);
        });
    },

    otpCounter() {
      this.count = 40

      const countdown = setInterval(() => {
        this.btnText = "Send OTP in " + this.count
        this.count--        

        if (this.count === 0) {
          this.btnDisabled = false
          this.btnText = "SEND OTP"
          clearInterval(countdown);
        }
      }, 1000); 
    },

    verify() {
      let that = this

      let data = {}
      data.idNumber = that.getNationalID
      data.OTP = that.otp

      that.buttonIcon = true
      that.buttonDisabled = true 

      api.verifyOTP(data)
        .then((response) => {
          that.showSuccess("OPT validated successfully")
          that.fetchUserDetails()
        })
        .catch(function (error) {
          that.buttonIcon = false
          that.buttonDisabled = false

          that.showError(error.response.data.message);
        });
    },

    fetchUserDetails() {
      let that = this

      api.userDetails(that.getNationalID)
        .then((response) => {
          that.setUserDetails(response.data[0])
          localStorage.setItem("userDetails", JSON.stringify(response.data[0]))
          
          that.fetchClientDetails()
        })
        .catch(function (error) {
          that.buttonIcon = false
          that.buttonDisabled = false
          that.showError(error.response.data.message);
        });
    },

    fetchClientDetails() {
      let that = this

      api.clientDetails()
        .then((response) => {
          if(response.status != 200) {
            that.showError("Could not authenticate user")
          }
          else {
            localStorage.setItem("clientAccounts", JSON.stringify(response.data.details))
            that.setClientAccounts(response.data.details)
            that.$router.push("account/balance")

            let time = new Date()
            localStorage.setItem("loginTime", time)
            EventBus.$emit('trackLoginTimer')
          }
          
          that.buttonIcon = false
          that.buttonDisabled = false
        })
        .catch(function (error) {
          that.buttonIcon = false
          that.buttonDisabled = false
          that.showError(error.response.data.message);
        });
    }
  }
}
</script>
  