<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="custom-customer-care-image">
                        <div class="row">
                            <div class="col-lg-6 mb-2">
                                <b-card class="custom-customer-care-card" header="CONTACT US" header-class="custom-customer-care-header-1" footer="FAQs" 
                                    footer-class="custom-customer-care-footer-1">
                                    <b-row>
                                        <font-awesome-icon class="custom-icon-md mt-2 mr-2" icon="fa-solid fa-phone" />
                                        <p class="custom-customer-care-text">(+254)729 333 444, (+254)111 026 100</p>
                                    </b-row>

                                    <b-row>
                                        <font-awesome-icon class="custom-icon-md mt-2 mr-2" icon="fa-brands fa-whatsapp" />
                                        <p class="custom-customer-care-text">(+254)718 079 339</p>
                                    </b-row>

                                    <b-row>
                                        <font-awesome-icon class="custom-icon-md mt-2 mr-2" icon="fa-solid fa-envelope" />
                                        <p class="custom-customer-care-text">info@ibsbank.so</p>
                                    </b-row>
                                    <p class="text-black mt-4">Follow us on social media</p>

                                    <b-row>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateFacebook()" class="custom-icon-lg" icon="fa-brands fa-facebook" />
                                        </b-col>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateTwitter()" class="custom-icon-lg" icon="fa-brands fa-x-twitter" />
                                        </b-col>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateInstagram()" class="custom-icon-lg" icon="fa-brands fa-instagram" />
                                        </b-col>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateYoutube()" class="custom-icon-lg" icon="fa-brands fa-youtube" />
                                        </b-col>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateLinkedin()" class="custom-icon-lg" icon="fa-brands fa-linkedin" />
                                        </b-col>
                                        <b-col class="p-0">
                                            <font-awesome-icon @click="navigateTikTok()" class="custom-icon-lg" icon="fa-brands fa-tiktok" />
                                        </b-col>
                                    </b-row>                                    
                                </b-card>
                            </div>

                            <div class="col-lg-6">
                                <b-card class="custom-customer-care-card" header="REPORT SOMETHING" header-class="custom-customer-care-header-3">
                                    <p class="custom-customer-care-text">
                                        Stay vigilant and report anything suspicious
                                    </p>
                                    <ol>
                                        <li>
                                            Report suspicious emails, calls or messages
                                        </li>
                                        <li>
                                            Report an online vulnerability
                                        </li>
                                        <li>
                                            Report fraud on your account
                                        </li>
                                        <li>
                                            Report suspicious conduct 
                                        </li>
                                    </ol>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import router from '@/router'

export default {
    name: 'CustomerCareView',
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
            //
        }

    },

    methods: {
        navigateFacebook() {
            window.open("https://www.facebook.com/IBSBankSo/")
        },

        navigateTwitter() {
            window.open("https://twitter.com/IBSBankSo")
        },

        navigateInstagram() {
            window.open("https://www.instagram.com/ibsbankso/")
        },

        navigateYoutube() {
            window.open("https://www.youtube.com/ibsbankso")
        },

        navigateLinkedin() {
            window.open("https://www.linkedin.com/company/ibsbankso")
        },

        navigateTikTok() {
            window.open("https://www.tiktok.com/discover/ibs-bank")
        }
    }
}
</script>
  