import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticated: false,
    token: null,
    user: null,
    registrationData: null,
    accounts: null,
    nationalID: null,
    sidebar: true,
    beforeTerms: null,
    clientID: '',
  },

  getters: {
    getIdNumber(state) {
      if(state.user != null) {
        return state.user.attributes.idNumber
      }
      else {
        return null
      }
    },
    
    getPhoneNumber(state) {
      if(state.user != null) {
        return state.user.attributes.phoneNumber
      }
      else {
        return null
      }
    },
    getAccounts: state => state.accounts,
    getUser: state => state.user,
    getNationalID: state => state.nationalID,
    getRegistrationData: state => state.registrationData,
    getSiderbar: state => state.sidebar,
    getBeforeTerms: state => state.beforeTerms,
    getClientID: state => state.clientID
  },

  mutations: {
    setToken(state, value) {
      state.token = value
    },

    setUserDetails(state, value) {
      state.user = value
    },

    setClientAccounts(state, value) {
      state.accounts = value
    },

    setRegistrationData(state, value) {
      state.registrationData = value
    },

    setNationalID(state, value) {
      state.nationalID = value
    },

    setSidebar(state, value) {
      state.sidebar = value
    },

    setBeforeTerms(state, value) {
      state.beforeTerms = value
    },

    setClientID(state, value) {
      state.clientID = value
    },

    logout(state) {
      state.token = null
      state.user = null
      state.accounts = null
    },
  },

  actions: {
  },
  
  modules: {
  }
})
