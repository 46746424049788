<template>
    <div class="transfer">
        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <b-row>
                        <b-col lg="12">
                            <b-card>
                                <template v-if="submitStatus">
                                    <div class="d-flex justify-content-center mt-4">
                                        <div class="d-flex flex-column custom-mw-400">
                                            <img class="mx-auto" src="@/assets/img/success.png" width="80px">
                                            <p class="text-center custom-subtitle-text-bold-1 mt-2 mb-0">Beneficiary added successfully!</p>

                                            <b-button class="custom-logout-logout mx-auto custom-mw-100 px-4 mt-4"
                                                @click="submit()">
                                                Return
                                            </b-button>
                                        </div>

                                    </div>

                                </template>

                                <template v-else>
                                    <p class="custom-account-statement-title">
                                        Beneficiary Management
                                    </p>

                                    <b-row>
                                        <b-col class="pl-0" lg="6">
                                            <label><strong>Bank</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="banks" v-model="selectedBank">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col lg="6">
                                            <label><strong>Beneficiary Name</strong></label><br />
                                            <input type="text" v-model="name" placeholder="Name of beneficiary"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>
                                    </b-row>

                                    <b-row class="mt-3">
                                        <b-col class="pl-0" lg="6">
                                            <label><strong>Address</strong></label><br />
                                            <input type="text" v-model="address" placeholder="Enter physical address"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6">
                                            <label><strong>City</strong></label><br />
                                            <input type="text" v-model="city" placeholder="Enter city"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>
                                    </b-row>

                                    <b-row class="mt-3">
                                        <b-col class="pl-0" lg="6">
                                            <label><strong>Postal code</strong></label><br />
                                            <input type="text" v-model="postalCode" placeholder="Enter postcal code"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6">
                                            <label><strong>Country</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="countries" v-model="selectedCountry">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mt-3">
                                        <b-col class="pl-0" lg="6">
                                            <label><strong>Phone Number</strong></label><br />
                                            <input type="text" v-model="phoneNumber" placeholder="Enter phone number"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6">
                                            <label><strong>Email</strong></label><br />
                                            <input type="text" v-model="email" placeholder="Enter email"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>
                                    </b-row>
                                    <hr />

                                    <div class="d-flex justify-content-end mt-4">
                                        <b-button-group>
                                            <b-button class="custom-auth-signin my-1" @click="clear()">
                                                Clear
                                            </b-button>

                                            <b-button class="custom-auth-register custom-btn-group my-1 ml-1 px-4"
                                                @click="submit()">
                                                Submit
                                            </b-button>
                                        </b-button-group>
                                    </div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'BeneficiaryManagement',
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
            name: null,
            address: null,
            city: null,
            postalCode: null,
            phoneNumber: null,
            email: null,
            banks: ["IBS Bank", "KCB Bank", "Equity Bank", "ABSA"],
            selectedBank: null,
            selectedCountry: null,
            countries: ["Kenya", "Somalia", "Uganda"],
            submitStatus: false
        }
    },

    methods: {
        clear() {
            this.name = null
            this.address = null
            this.city = null
            this.postalCode = null
            this.selectedCountry = null
            this.phoneNumber = null
            this.email = null
            this.selectedBank = null
        },

        submit() {
            this.submitStatus = !this.submitStatus
        }
    }
}
</script>