<template>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="111400" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait"
        :filename="data.type + ' Account Statement ' + data.dateFrom + ' - ' + data.dateTo" :pdf-quality="2"
        @hasDownloaded="onDownload($event)" ref="html2Pdf">
        <div slot="pdf-content">
            <div class="pdf-item p-4">
                <b-row>
                    <b-col cols="9">
                        <small class="custom-small-text">Plan Smarter. Live Better.</small>
                    </b-col>
                    <b-col cols="3">
                        <img class="p-2" height="50px" src="../assets/img/logo-alt.png" />
                    </b-col>
                </b-row>

                <span class="card-title px-2">Account Statement</span><br />

                <b-row>
                    <b-col cols="3">
                        <span class="custom-subtitle-text-bold">Account Number</span> 
                    </b-col>
                    <b-col cols="9">
                        <span class="custom-subtitle-text">{{ data.account }}</span>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="3">
                        <span class="custom-subtitle-text-bold">Customer</span> 
                    </b-col>
                    <b-col cols="9">
                        <span class="custom-subtitle-text">{{ getUser.firstName + ' ' + getUser.lastName + ' | ' + getClientID }}</span>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="3">
                        <span class="custom-subtitle-text-bold">Statement Date</span> 
                    </b-col>
                    <b-col cols="9">
                        <span class="custom-subtitle-text">From {{ data.dateFrom }} To {{ data.dateTo }} </span>
                    </b-col>
                </b-row>
            </div>

            <div class="pdf-item px-4">
                <table class="table table-striped table-bordered table-sm custom-table">
                    <thead>
                        <tr>
                            <th>
                                Transaction Date
                            </th>
                            <th>
                                Particulars
                            </th>
                            <th class="text-right">
                                Debit
                            </th>
                            <th class="text-right">
                                Credit
                            </th>
                            <th class="text-right">
                                Balance
                            </th>
                        </tr>
                    </thead>

                    
                    <tbody>
                        <tr class="mt-2 custom-table-row" v-for="transaction, index in transactions" :key="index">
                            <td>{{ formatDate(transaction.trxDate) }}</td>
                            <td>{{ transaction.particulars }}</td>
                            <td class="text-right">
                                <span v-if="transaction.debit > 0">{{ formatAmount(transaction.debit) }}</span>
                            </td>
                            <td class="text-right">
                                <span v-if="transaction.credit > 0">{{ formatAmount(transaction.credit) }}</span>
                            </td>
                            <td class="text-right">{{ formatAmount(transaction.closing) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pdf-item p-4">
                <b-row>
                    <small class="custom-small-text">IBS Bank</small>
                </b-row>
                <b-row>
                    <small class="custom-small-text">
                        Opposite Deero Mall, Road No.1, 26 June District, Hargeisa, Somaliland.
                        Tel (060) 203 0254, 0729 333 444 Email: hello@ibsbank.so
                        www.ibsbank.so
                    </small>
                </b-row>

                <div class="row mb-4 shadow" width="100%">
                    <div class="custom-row-green col-3"></div>
                    <div class="custom-row-red col-3"></div>
                    <div class="custom-row-blue col-3"></div>
                    <div class="custom-row-dark-blue col-3"></div>
                </div>
            </div>
        </div>
    </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { mapGetters } from "vuex";

export default {
    name: 'Export',
    props: {
        transactions: null,
        data: {},
    },

    components: {
        VueHtml2pdf
    },

    computed: {
        ...mapGetters(['getUser', 'getClientID'])
    },

    mounted() {
        this.downloadPDF()
    },

    data() {
        return {
            fields: [
                { key: "transactionDate" },
                { key: "particulars" },
                { key: "debit", thClass: 'text-right', tdClass: 'text-right' },
                { key: "credit", thClass: 'text-right', tdClass: 'text-right' },
                { key: "balance", thClass: 'text-right', tdClass: 'text-right' }
            ],
        }
    },

    methods: {
        downloadPDF() {
            try {
                this.$refs.html2Pdf.generatePdf()
            }
            catch {
                this.showError("Unable to download file. Please try again later")
                this.$emit('unmount-export-component')
            }
        },

        formatDate(date) {
            if (date == "-") {
                return date
            }
            else {
                let newDate = new Date(date)
                const yyyy = newDate.getFullYear()
                let mm = newDate.getMonth() + 1;
                let dd = newDate.getDate()

                if (dd < 10) dd = '0' + dd
                if (mm < 10) mm = '0' + mm

                return dd + '-' + mm + '-' + yyyy
            }
        },

        formatAmount(amount) {
            let formattedNumber = new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount)

            return formattedNumber
        },

        onDownload(e) {
            this.$emit('unmount-export-component', e);
        }
    }
}
</script>
