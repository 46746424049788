<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Loan Guarantors
                                </p>
                                <b-table striped hover :fields="fields" :items="myGuarantors" bordered :busy="isBusy" show-empty responsive>
                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong> Loading...</strong>
                                        </div>
                                    </template>

                                    <template #empty="scope">
                                        <p>No data</p>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import api from "../../apis/api";
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'LoanApplicationsView',
    components: {
        SideNav,
        TopNav,
        Footer
    },
    data() {
        return {
            fields: [
                "Name",
                "GuaranteeAmount",
                "ProductName",
            ],

            myGuarantors: null,
            isBusy: true
        }

    },

    computed: {
        ...mapGetters(['getPhoneNumber'])
    },

    created() {
        this.fetchGuarantorDetails();
    },

    methods: {
        fetchGuarantorDetails() {
            let that = this

            api.gaurantors(this.getPhoneNumber)
                .then((response) => {
                    console.log(response.data.myGuarantors)
                    that.myGuarantors = response.data.myGuarantors
                    that.isBusy = false
                })
                .catch(function (error) {
                    that.showError(error.response.data.message)
                    that.isBusy = false
                });
        }
    }
}
</script>
