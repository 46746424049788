<template>
    <div class="register">
      <TopNav />

      <div id="wrapper">
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">

            <div class="row">
              <div class="col-lg-6 p-0">
                  <img src="../../assets/img/background-12.png" width="100%"/>
              </div>

              <div class="custom-register-container-1 col-lg-6 p-4">
                <div class="row custom-register-container-2">
                    <div class="col-1">
                        <font-awesome-icon class="custom-register-icon" icon="fa-angle-left" @click="navigateAuthChoose()"/>
                    </div>
                    <div class="col-4">
                        <p class="custom-auth-subtitle m-0">Register</p>  
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1"></div>

                    <div class="col-lg-10">
                        <small class="text-black">Create your profile below:</small>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-1"></div>
                    
                    <div class="col-lg-4">
                        <label class="text-black m-0">First Name:</label>
                        <b-form-input class="custom-auth-input" placeholder="Enter first name" v-model="firstName"></b-form-input>
                    </div>

                    <div class="col-lg-4">
                        <label class="text-black m-0">Last Name:</label>
                        <b-form-input class="custom-auth-input" placeholder="Enter last name"  v-model="lastName"></b-form-input>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-1"></div>
                    
                    <div class="col-lg-4">
                        <label class="text-black m-0">Phone Number:</label>
                        <b-form-input class="custom-auth-input" placeholder="Enter phone number" v-model="phoneNumber"></b-form-input>
                    </div>

                    <div class="col-lg-4">
                        <label class="text-black m-0">Email:</label>
                        <b-form-input class="custom-auth-input" placeholder="Enter email"  v-model="email"></b-form-input>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-1"></div>
                    
                    <div class="col-lg-4">
                        <label class="text-black m-0">ID Number:</label>
                        <b-form-input type="number" class="custom-auth-input" placeholder="Enter ID number" v-model="idNumber"></b-form-input>
                    </div>
                </div>
                     
              </div>
            </div>
  
            <div class="row custom-register-container-3 p-4 shadow">            
              <div class="col-lg-6">
                <p class="text-black m-0">
                  Manage your cash and access loans and easily pay utility bills through IBS
                </p>
              </div>
              <div class="col-lg-6 p-4">
                <div class="row">
                  <div class="col-lg-1"></div>
                
                  <div class="col-lg-11">
                    <b-form-checkbox class="custom-register-checkbox" id="checkbox-1" @change="acceptTerms($event)">
                      <p class="text-black">I accept the <span class="custom-register-link custom-underline" @click="navigateTerms()">Terms and Conditions</span></p>
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-1"></div>
                
                  <div class="col-lg-6 py-4">
                    <b-button class="custom-auth-signin" @click="registerUser()" :disabled="!btnDisabled">
                      <span>CREATE ACCOUNT</span>
                    </b-button>
                    
                  </div>
                </div>
              </div>             
              
            </div>  

            <div class="row custom-auth-container-2 p-4 shadow">            
              <div class="col-lg-6 p-2">
                <p class="custom-auth-subtitle m-0">
                  IBS empowers your financial endeavours; a friend by your side
                </p>
              </div>
              <div class="col-lg-6 p-2">
                <small class="text-black">
                  If you are having issues in registration or login to Online Banking service, please contact our contact center or write to us.
                </small>

                <div class="row mt-4">
                  <div class="col-6 p-0">
                    <small class="custom-auth-heading">Phone Number</small>
                  </div>
                  <div class="col-6 p-0">
                    <small class="custom-auth-heading">Email</small>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 p-0">
                    <small class="text-black">+254 729 333 444</small>
                  </div>
                  <div class="col-6 p-0">
                    <small class="text-black">info@ibsbank.so</small>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>

      <Footer />

    </div>
  </template>
  
  <script>

  import { mapState, mapMutations } from 'vuex'
  import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import Footer from '@/components/Footer.vue'
  
  export default {
    name: 'RegisterView',
    data() {
        return {
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          idNumber: null,
          btnDisabled: false
        }
    },
    components: {
      SideNav,
      TopNav,
      Footer
    },
    methods: {
      ...mapMutations([
        'setRegistrationData'
      ]),

      navigateAuthChoose() {
        this.$router.push("auth-choose")
      },

      navigateTerms() {
        this.$router.push("terms")
      },

      acceptTerms(e) {
        this.btnDisabled = e
      },

      registerUser() {
        let that = this

        let data = {
          "firstName": that.firstName,
          "lastName": that.lastName,
          "username": that.idNumber,
          "email": that.email,
          "idNumber": that.idNumber,
          "phoneNumber": that.phoneNumber,
          "password": null
        }

        if(that.firstName == null || that.lastName == null || that.phoneNumber == null 
          || that.email == null || that.idNumber == null) {
            that.showError("All fields are mandatory");
          }
        else {
          that.setRegistrationData(data)
          that.$router.push("set-password")
        }
      }
    }
  }
  </script>
  