<template>
    <LineGraphChart :options="chartOptions" :data="chartData"/>
</template>
  
  <script>
  import { Line as LineGraphChart } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, LineElement)
  
  export default {
    name: 'LineGraph',
    components: { LineGraphChart },
    props: {
        lineGraphData: {},
        selectedProduct: {}
    },
    
    data() {
      return {
        chartData: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: true
        }
      }
    },
    created() {
       this.formatChart()
    },

    methods: {
        formatChart() {
            let labels = []
            let dts = []

            this.lineGraphData.forEach(function(item) {
                labels.push(item.months)
                dts.push(item.closingBalance)
            })

            let datasets = []
            let dt = {}
            dt.backgroundColor = this.selectedProduct.colorCode,
            dt.label = this.selectedProduct.productType,
            dt.data = dts
            datasets.push(dt)

            let cd = {}
            cd.labels = labels
            cd.datasets = datasets

            this.chartData = cd   
        }
    }
  }
  </script>