<template>
    <div class="auth-choose">
      <TopNav />

      <div id="wrapper">
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">

            <div class="row">
              <div class="col-lg-6 p-0">
                  <img src="../../assets/img/background-12.png" width="100%"/>
              </div>
              <div class="col-lg-3 m-4">
                <div class="row m-4">
                  <b-button class="custom-auth-signin" @click="navigateLogin()">SIGN IN</b-button>
                </div>
                <div class="row m-4">
                  <b-button class="custom-auth-register" @click="navigateRegister()">REGISTER</b-button>
                </div>                 
              </div>
            </div>
  
            <div class="row custom-auth-container-1 p-4">            
              <div class="col-lg-6 px-4">
                <p class="text-black m-0">
                  Looking to plan your money in a smarter way and live better? You have come to the right place
                </p>
              </div>
            </div>  

            <div class="row custom-auth-container-2 p-4 shadow">            
              <div class="col-lg-6 px-4">
                <p class="custom-auth-subtitle m-0">
                  Opening your IBS account is simple, fast and convenient, without having to visit the Branch
                </p>
              </div>
              <div class="col-lg-6 px-4">
                <small class="text-black">
                  If you are having issues in registration or login to Online Banking service, please contact our contact center or write to us.
                </small>

                <div class="row mt-4">
                  <div class="col-6">
                    <small class="custom-auth-heading">Phone Number</small>
                  </div>
                  <div class="col-6">
                    <small class="custom-auth-heading">Email</small>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <small class="text-black">+254 729 333 444</small>
                  </div>
                  <div class="col-6">
                    <small class="text-black">info@ibsbank.so</small>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>

      <Footer />

    </div>
  </template>
  
  <script>
  import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import Footer from '@/components/Footer.vue'
  
  export default {
    name: 'AuthChoose',
    components: {
      SideNav,
      TopNav,
      Footer
    },
    methods: {
      navigateRegister() {
        this.$router.push("register")
      },

      navigateLogin() {
        this.$router.push("login")
      }
    }
  }
  </script>
  