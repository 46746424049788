import apim from './apim'
import store from '../store/index'


export default {
    register(data) {
        return apim().post('/api/auth/create/user', data)
    },

    login(data) {
        return apim().post('/api/auth/user/authenticate', data)
    },

    passwordChange(data) {
        return apim().put('/api/change/user/password', data)
    },

    passwordForgot(data) {
        return apim().put('/api/reset/user/password', data)
    },

    userDetails(data) {
        return apim().get('/api/get/user/details?username=' + data)
    },

    clientDetails() {
        return apim().get('/api/v1/client/details?request_ref=' + process.env.VUE_APP_REQUEST_REF +
            "&client_id=&account_id=&mobile_number=&id_number=" + store.getters.getNationalID + "&value_key=ACC")
    },

    statement(data) {
        return apim().get('/api/v1/client/statement?id_number=' + store.getters.getNationalID + '&request_ref=' + process.env.VUE_APP_REQUEST_REF +
            '&branch=' + process.env.VUE_APP_BRANCH + '&account_id=' + data.accountID + '&from_date=' + data.dateFrom + '&to_date=' + data.dateTo)
    },

    calculator(data) {
        return apim().get('/api/v1/loans/calculator?id_number=' + store.getters.getNationalID + '&request_ref=' + process.env.VUE_APP_REQUEST_REF +
            '&client_id=0317534&product_id=' + data.product + '&loan_amount=' + data.loanAmount + '&loan_term=' +
            data.period + '&disbursement_date=' + data.date)
    },

    applications(phoneNumber) {
        return apim().get('/api/v1/loans/status?id_number=' + store.getters.getNationalID + '&request_ref=' + process.env.VUE_APP_REQUEST_REF +
            '&request_type=LOAN-STATUS&mobile_number=' + phoneNumber)
    },

    products() {
        return apim().get('/api/v1/product/products?id_number=' + store.getters.getNationalID + '&request_ref=' + process.env.VUE_APP_REQUEST_REF + '&product_id=')
    },

    gaurantors(phoneNumber) {
        return apim().get('/api/v1/guarantor/details?id_number=' + store.getters.getNationalID + '&request_ref='+process.env.VUE_APP_REQUEST_REF+
            '&mobile_number=' + phoneNumber)
    },

    summary() {
        return apim().get('/api/v1/client/summary?request_ref=' + process.env.VUE_APP_REQUEST_REF +
            '&id_number=' + store.getters.getNationalID + '&mobile_number=')
    },

    chart(data) {
        return apim().get('/api/v1/client/graph?id_number=' + store.getters.getNationalID + '&product_id=' + data.product)
    },

    sendOTP(data) {
        return apim().post('/api/send/otp', data)
    },

    verifyOTP(data) {
        return apim().post('/api/validate/otp', data)
    }
}