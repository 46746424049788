<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Shares Account Statement
                                </p>

                                <div class="row mb-2">
                                    <div class="col-lg-3">
                                        <label><strong>Select Account</strong></label><br />
                                        <b-form-select v-model="selectedAccount" :options="accountNumbers"
                                            text-field="accountID" value-field="account"
                                            class="custom-account-statement-filter-input"></b-form-select>
                                    </div>

                                    <div class="col-lg-3">
                                        <label><strong>Date from</strong></label><br />
                                        <input type="date" v-model="dateFrom" v-bind:max="maxDate"
                                            class="custom-account-statement-filter-input form-control">
                                    </div>

                                    <div class="col-lg-3">
                                        <label><strong>Date To</strong></label><br />
                                        <input type="date" v-model="dateTo" v-bind:max="maxDate"
                                            class="custom-account-statement-filter-input form-control">
                                    </div>

                                    <div class="col-lg-2">
                                        <label></label><br />
                                        <b-button-group>
                                            <b-button class="custom-auth-signin mt-1" @click="fetchAccountStatement()"
                                                :disabled="btnDisabled">
                                                <b-spinner class="custom-spinner" label="Spinning" v-if="btnIcon"
                                                    small></b-spinner>
                                                <span v-if="!btnIcon">VIEW</span>
                                            </b-button>
                                            <b-button class="custom-auth-signin custom-btn-group mt-1" @click="mountComponent()" :disabled="exportBtn">
                                                <b-spinner class="custom-spinner" label="Spinning" v-if="exportBtnIcon"
                                                    small></b-spinner>
                                                <span v-if="!exportBtnIcon">DOWNLOAD</span>
                                            </b-button>
                                        </b-button-group>
                                    </div>


                                </div>

                                <b-table striped hover bordered :fields="fields" :items="transactions" responsive
                                    :busy="isBusy" show-empty ref="table">
                                    <template #cell(transactionDate)="row">
                                        <span>{{ formatDate(row.item.trxDate) }}</span>
                                    </template>
                                    <template #cell(debit)="row">
                                        <span v-if="row.item.debit > 0">{{ formatAmount(row.item.debit) }}</span>
                                    </template>
                                    <template #cell(credit)="row">
                                        <span v-if="row.item.credit > 0">{{ formatAmount(row.item.credit) }}</span>
                                    </template>
                                    <template #cell(balance)="row">
                                        <span>{{ formatAmount(row.item.closing) }}</span>
                                    </template>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>

                                    <template #empty="scope">
                                        <p>No data</p>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="exportComponent">
                <Export :data="componentData" :transactions="transactions" v-on:unmount-export-component="unmountComponent"></Export>
            </template>            
        </div>
        <Footer />
    </div>
</template>

<script>
import api from "../../apis/api";
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import Export from '@/components/Export.vue'

export default {
    name: 'LoanApplicationsView',
    components: {
        SideNav,
        TopNav,
        Footer,
        Export
    },
    data() {
        return {
            fields: [
                { key: "transactionDate" },
                { key: "particulars" },
                { key: "debit", thClass: 'text-right', tdClass: 'text-right' },
                { key: "credit", thClass: 'text-right', tdClass: 'text-right' },
                { key: "balance", thClass: 'text-right', tdClass: 'text-right' }
            ],

            transactions: null,
            accountNumbers: [],
            selectedAccount: null,
            dateFrom: null,
            dateTo: null,
            btnDisabled: false,
            btnIcon: false,
            maxDate: new Date().toISOString().substr(0, 10),
            isBusy: false,

            exportComponent: false,
            componentData: null,
            exportBtn: false,
            exportBtnIcon: false,
        }

    },

    computed: {
        ...mapGetters(['getAccounts'])
    },

    created() {
        this.filterSavingsAcocunts()
    },

    methods: {
        filterSavingsAcocunts() {
            let that = this

            that.getAccounts.map(function (item) {
                if (item.productType === "SHARES") {
                    let account = {
                        accountID: item.accountID + " - " + item.productID,
                        account: item.accountID
                    }
                    that.accountNumbers.push(account)
                }
            });
        },

        fetchAccountStatement() {
            let that = this

            if (that.selectedAccount == null || that.dateFrom == null || that.dateTo == null) {
                that.showError("Cannot apply filter on empty fields. ");
            }
            else {
                that.isBusy = true
                that.btnIcon = true
                that.btnDisabled = true

                let data = {}
                data.accountID = that.selectedAccount
                data.dateFrom = that.dateFrom
                data.dateTo = that.dateTo

                api.statement(data)
                    .then((response) => {
                        that.transactions = response.data.details

                        that.isBusy = false
                        that.btnIcon = false
                        that.btnDisabled = false
                    })
                    .catch(function (error) {
                        that.isBusy = false
                        that.btnIcon = false
                        that.btnDisabled = false

                        that.showError(error.response.data.message);
                    });
            }


        },

        formatDate(date) {
            if (date == "-") {
                return date
            }
            else {
                let newDate = new Date(date)
                const yyyy = newDate.getFullYear()
                let mm = newDate.getMonth() + 1;
                let dd = newDate.getDate()

                if (dd < 10) dd = '0' + dd
                if (mm < 10) mm = '0' + mm

                return dd + '-' + mm + '-' + yyyy
            }
        },

        formatAmount(amount) {
            let formattedNumber = new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount)

            return formattedNumber
        },

        mountComponent() {
            if(this.transactions == null) {
                this.showError("No data available for export")
           }
            else {
                let that = this
                let data = {}

                data.type = "Shares"
                data.dateFrom = that.dateFrom
                data.dateTo = that.dateTo
                data.account = that.selectedAccount

                that.componentData = data                
                that.exportBtn = true
                that.exportBtnIcon = true
                that.exportComponent = true
           }
        },

        unmountComponent() {
            let that = this

            that.componentData = null
            
            that.exportBtn = false
            that.exportBtnIcon = false
            that.exportComponent = false
        }
    }
}
</script>
