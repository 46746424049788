<template>
    <div>
        <nav class="navbar navbar-expand custom-top-nav static-top"></nav>

        <nav class="navbar navbar-expand custom-middle-nav-background topbar static-top custom-desktop-navigation">
            <div class="custom-logo-container ml-4" @click="navigateRoot()">
                <img height="40px" src="../assets/img/logo.png"/>
            </div>

            <ul class="navbar-nav ml-auto">
                <template v-if="getUser != null">
                    <div class="topbar-divider d-none d-sm-block"></div>

                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <img class="img-profile rounded-circle"
                                src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
                            <div>
                                <b-dropdown :text="getUser.firstName + ' ' + getUser.lastName + ' | ' + getClientID"
                                    class="custom-auth-username m-md-2" variant='link'>
                                    <b-dropdown-item @click="navigateChangePassword()">Change Password</b-dropdown-item>
                                    <b-dropdown-item @click="navigateLogout()">Logout</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <span class="ml-2 d-none d-lg-inline text-white small"></span>
                        </a>
                    </li>
                </template>
                <template v-else>
                    <li class="nav-item">
                        <span class="nav-link custom-nav-link" @click="navigateAuthChoose()">
                            <b-button class="custom-top-nav-button">
                                MEMBER'S PORTAL
                                <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                            </b-button>
                        </span>
                    </li>
                </template>


            </ul>
        </nav>

        <nav class="navbar custom-middle-nav-background topbar static-top  custom-mobile-navigation">
            <ul class="navbar-nav">
                <template v-if="getUser != null">
                    <font-awesome-icon v-if="!getSiderbar" class="custom-menu-icon ml-2" icon="fa-solid fa-bars"
                        @click="toggleSidebar()" />
                    <font-awesome-icon v-if="getSiderbar" class="custom-menu-icon ml-4" icon="fa-solid fa-times"
                        @click="toggleSidebar()" />
                </template>
                <template v-else>
                    <span class="nav-link custom-nav-link" @click="navigateAuthChoose()">
                        <span class="nav-link custom-nav-link" @click="navigateAuthChoose()">
                            <b-button class="custom-top-nav-button">
                                MEMBER'S PORTAL
                                <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                            </b-button>
                        </span>
                    </span>
                </template>
            </ul>

            <div>
                <img height="40px" src="../assets/img/logo.png" />
            </div>
        </nav>

        <div class="row mb-3 shadow" width="100%">
            <div class="custom-row-green col-3"></div>
            <div class="custom-row-red col-3"></div>
            <div class="custom-row-blue col-3"></div>
            <div class="custom-row-white shadow col-3"></div>
        </div>
    </div>

</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from '@/main';

export default {
    name: 'TopNav',
    data() {
        return {
            //
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getSiderbar', 'getClientID'])
    },

    created() {
        //
    },
    methods: {
        ...mapMutations(['setSidebar', 'logout']),

        navigateAuthChoose() { 
            this.$router.push("/auth-choose")
        },

        navigateChangePassword() {
            this.$router.push("/change-password")
        },

        navigateLogout() {    
            EventBus.$emit('logoutUser')   
        },

        navigateRoot() {
            this.$router.push("/")
        },

        toggleSidebar() {
            this.setSidebar(!this.getSiderbar)
        }
    }
}
</script>