<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="custom-account-image">
                        <b-card>
                            <div class="row">
                                <div class="col-1">
                                    <img class="custom-user-image" src="../../assets/img/user.png" width="100%" />
                                </div>
                                <div class="col-11 custom-logo-container">
                                    <h3 class="custom-user-name">{{ fullName }}</h3>
                                </div>
                            </div>
                            <hr />

                            <div class="row" v-for="account, index in getAccounts" :key="account.accountID">
                                <div class="col-lg-1">
                                    <b-form-group>
                                        <label><strong>#</strong></label><br class="custom-desktop"/>
                                        <label class="text-black">{{index + 1}}</label>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <b-form-group>
                                        <label><strong>Account Number</strong></label><br />
                                        <label class="text-black">{{account.accountID}}</label>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group>
                                        <label><strong>Product Type</strong></label><br />
                                        <label class="text-black">{{account.productType}}</label>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group>
                                        <label><strong>Status</strong></label><br />
                                        <template v-if="account.accountStatus == 'Active'">
                                            <label class="text-success font-weight-bold">{{account.accountStatus}}</label>
                                        </template>    
                                        <template v-else>
                                            <label class="text-danger font-weight-bold">{{account.accountStatus}}</label>
                                        </template>                                    
                                    </b-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <b-form-group>
                                        <label><strong>Available Balance</strong></label><br />
                                        <h5 class="text-black">{{account.currencyID}} {{account.availableBalance.toLocaleString()}}</h5>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-card>

                        <div class="row mt-4">
                            <div class="col-lg-2">
                                <p class="custom-account-subtitle">Focus on growth with flexible funding</p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Quick <br /> Application</p>
                                <p class="text-black">
                                    Apply online in minutes and connect your business accounts, and we'll look at your financial
                                    info in real time to give you a decision.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Flexible <br />Access</p>
                                <p class="text-black">
                                    Use what you need when you need it - with affordable interest rates.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Term Length <br />Options</p>
                                <p class="text-black">
                                    15-, 30-, or 45-days loan term options with flexible timely fees.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Straightforward <br /> Fees</p>
                                <p class="text-black">
                                    No hidden extra charges. Calculate your loan repayment fee before applying
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'accountView',
    components: {
        SideNav,
        TopNav,
        Footer
    },

    data() {
        return {
            fullName: null
        }
    },

    computed: {
        ...mapGetters(['getUser', 'getAccounts'])
    },

    created() {
        this.account();
    },

    methods: {
        account() {
            this.fullName = this.getUser.firstName + " " + this.getUser.lastName
        }
    }
}
</script>
  