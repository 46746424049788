<template>
  <div class="home">
    <TopNav />
    <div>

      <div id="content-wrapper" class="d-flex flex-column mb-4">
        <div id="content">
          <img src="../assets/img/background-10.jpg" width="100%" />

          <div class="custom-image-bg">
            <div class="row p-4">
            <h3 class="custom-account-statement-title">Ways to bank</h3>
          </div>

          <div class="px-4 mb-4">
            <carousel :perPage="getScreenSize()">
              <slide>
                <b-card title="Banking Hall" :img-src="getImagePath('banking_hall.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="IBS Mobile Banking" :img-src="getImagePath('mobile_banking.jpg')" img-alt="Image"
                  img-top tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="IBS Agency" :img-src="getImagePath('amicash_agency.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>

              <slide>
                <b-card title="IBS Membership Card" :img-src="getImagePath('membership_card.jpg')" img-alt="Image"
                  img-top tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Western Union" :img-src="getImagePath('western_union.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Paybill 162180" :img-src="getImagePath('paybill.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>

              <slide>
                <b-card title="Free Paybill 4298298" :img-src="getImagePath('free_paybill.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Cheque Book" :img-src="getImagePath('chequebook.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Internal Cheaque Book" :img-src="getImagePath('internal_chequebook.jpg')" img-alt="Image"
                  img-top tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>

              <slide>
                <b-card title="Pesa Link" :img-src="getImagePath('pesalink.jpg')" img-alt="Image" img-top tag="article"
                  style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="RTFT Transfer" :img-src="getImagePath('rtft.jpg')" img-alt="Image" img-top tag="article"
                  style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Merchant Settlement" :img-src="getImagePath('merchant_settlement.jpg')" img-alt="Image"
                  img-top tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>

              <slide>
                <b-card title="Mpesa Services" :img-src="getImagePath('mpesa_services.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
              <slide>
                <b-card title="Bank Agents" :img-src="getImagePath('bank_agent.jpg')" img-alt="Image" img-top
                  tag="article" style="max-width: 20rem;" class="mb-2">
                </b-card>
              </slide>
            </carousel>
          </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import TopNav from '@/components/TopNav.vue'
import Footer from '@/components/Footer.vue'
import SideNav from '@/components/SideNav.vue'

export default {
  name: 'HomeView',
  components: {
    SideNav,
    TopNav,
    Footer,
    Carousel,
    Slide
  },

  data() {
    return {

    }
  },

  methods: {
    getImagePath(file) {
      return require(`@/assets/img/${file}`);
    },

    getScreenSize() {
      if (window.innerWidth < 768) {
        return 1
      }
      else {
        return 3
      }
    }
  }
}
</script>
