<template>
  <div class="register">
    <TopNav />

    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">

          <div class="row">
            <div class="col-lg-6 p-0">
              <img src="../../assets/img/background-12.png" width="100%" />
            </div>

            <div class="custom-register-container-1 col-lg-6 p-4">
              <div class="row custom-register-container-2">
                <div class="col-lg-1">
                  <font-awesome-icon class="custom-register-icon" icon="fa-angle-left" @click="navigateSetPassword()" />
                </div>
                <div class="col-lg-10">
                  <p class="custom-auth-subtitle m-0">Security Questions</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-1"></div>

                <div class="col-lg-6">
                  <small class="text-black">Set up security questions and answers:</small>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-10">
                  <label class="text-black m-0"><span class="custom-auth-question">a) Question:</span>
                    {{ securityQuestion1 }}</label>
                  <b-form-input class="custom-auth-input" placeholder="Name of your village which you were born? "
                    v-model="securityAnswer1"></b-form-input>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-10">
                  <label class="text-black m-0"><span class="custom-auth-question">b) Question:</span>
                    {{ securityQuestion2 }}</label>
                  <b-form-input class="custom-auth-input" placeholder="Name of your favourite town in Kenyan? "
                    v-model="securityAnswer2"></b-form-input>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-10">
                  <label class="text-black m-0"><span class="custom-auth-question">c) Question:</span> {{
                    securityQuestion3
                  }}</label>
                  <b-form-input class="custom-auth-input" placeholder="Name of your favourite musician?"
                    v-model="securityAnswer3"></b-form-input>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-6">
                  <b-button class="custom-auth-signin" @click="createAccount()" :disabled="btnDisabled">
                    <b-spinner class="custom-spinner" label="Spinning" v-if="btnIcon" small></b-spinner>
                    <span v-if="!btnIcon">SUBMIT</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="row custom-register-container-3 p-4 shadow">
            <div class="col-lg-6">
              <p class="text-black m-0">
                Making your life smarter and easier to manage your cash and life everyday, everywhere
              </p>
            </div>
          </div>

          <div class="row custom-auth-container-2 p-4 shadow">
            <div class="col-lg-6">
              <p class="custom-auth-subtitle m-0">
                IBS helps you enjoy the convenience of banking and access loans at the comfort of your home or at
                go!
              </p>
            </div>
            <div class="col-lg-6 px-4">
              <small class="text-black">
                If you are having issues in registration or login to Online Banking service, please contact our contact
                center or write to us.
              </small>

              <div class="row mt-4">
                <div class="col-6">
                  <small class="custom-auth-heading">Phone Number</small>
                </div>
                <div class="col-6">
                  <small class="custom-auth-heading">Email</small>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <small class="text-black">+254 729 333 444</small>
                </div>
                <div class="col-6">
                  <small class="text-black">info@ibsbank.so</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from "../../apis/api";
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'RegisterView',
  components: {
    SideNav,
    TopNav,
    Footer
  },
  computed: {
    ...mapGetters(['getRegistrationData'])
  },

  data() {
    return {
      securityQuestion1: "Which village were you born?",
      securityQuestion2: "What is your favourite town in Kenyan?",
      securityQuestion3: "Who is your favourite musician?",
      securityAnswer1: null,
      securityAnswer2: null,
      securityAnswer3: null,
      btnDisabled: false,
      btnIcon: false
    }
  },
  methods: {
    navigateSetPassword() {
      this.$router.push("set-password")
    },

    createAccount() {
      let that = this

      if (that.securityAnswer1 == null || that.securityAnswer1 == null || that.securityAnswer1 == null) {
        that.showError("Kindly answer all questions");
      }
      else {
        that.btnIcon = true
        that.btnDisabled = false 

        let data = that.getRegistrationData
        data.securityQuestion1 = that.securityQuestion1
        data.securityQuestion2 = that.securityQuestion2
        data.securityQuestion3 = that.securityQuestion3
        data.securityAnswer1 = that.securityAnswer1
        data.securityAnswer2 = that.securityAnswer2
        data.securityAnswer3 = that.securityAnswer3

        try {
          api.register(data)
            .then((response) => {
              that.btnIcon = false
              that.btnDisabled = true

              that.showSuccess("User account created successfully")
              this.$router.push("login")

            })
            .catch(function (error) {
              that.btnIcon = false
              that.btnDisabled = true
              that.showError(error.response)
            });
        } catch (error) {
          that.showError("Error sending request. Please try again later.")
        }        
      }

    },

    verifyRegistrationData() {
      let that = this

      if(that.getRegistrationData == null && that.getRegistrationData.password != null) {
        this.$router.push("register")
      }
    }
  },

  created() {
      this.verifyRegistrationData();
  },
}
</script>
