<template>
    <div class="transfer">
        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <b-row>
                        <b-col lg="12">
                            <b-card>
                                <template v-if="submitStatus">
                                    <div class="d-flex justify-content-center mt-4">
                                        <div class="d-flex flex-column custom-mw-400">
                                            <img class="mx-auto" src="@/assets/img/success.png" width="80px">
                                            <p class="text-center custom-subtitle-text-bold-1 mt-2 mb-0">Transaction
                                                Initiated Successfully!</p>
                                            <p class="text-center custom-small-text-1 mt-2">Your transaction has been
                                                initiated successfully. The transaction shall be completed within the
                                                next 2-3 hours.

                                            </p>
                                            <p class="text-center custom-small-text-1">
                                                Thank you
                                            </p>

                                            <b-button class="custom-logout-logout mx-auto custom-mw-100 px-4"
                                                @click="submit()">
                                                Return
                                            </b-button>
                                        </div>

                                    </div>

                                </template>

                                <template v-else>
                                    <p class="custom-account-statement-title">
                                        Swift Transfer
                                    </p>

                                    <b-row class="mt-4">
                                        <b-col lg="6" class="pl-0">
                                            <label><strong>From Account*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="accounts" @change="removeAccount($event)"
                                                text-field="accountNumber" value-field="accountName"
                                                v-model="selectedAccount">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col lg="6">
                                            <label><strong>Account Name</strong></label><br />
                                            <input type="text" v-model="selectedAccount"
                                                class="custom-account-statement-filter-input form-control" readonly>
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Building*</strong></label><br />
                                            <input type="text" v-model="building" placeholder="Enter building"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="mt-3">
                                            <label><strong>Street*</strong></label><br />
                                            <input type="text" v-model="street" placeholder="Enter street"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>City*</strong></label><br />
                                            <input type="text" v-model="city" placeholder="Enter city"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="mt-3">
                                            <label><strong>Country*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                v-model="selectedCountry" :options="countries">
                                                <option :value="null" disabled>-- Please select an option -- </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>IDD/PPN*</strong></label><br />
                                            <input type="text" v-model="id" placeholder="Enter id or passport number"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="mt-3">
                                            <label><strong>Date of Birth*</strong></label><br />
                                            <input type="date" v-model="dob" placeholder="Enter id or passport number"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>
                                    </b-row>
                                    <hr />

                                    <b-row class="mt-2">
                                        <b-col class="pl-0 mb-4" lg="12">
                                            <b-form-radio-group id="radio-group-1" v-model="selected" :options="options"
                                                :aria-describedby="ariaDescribedby"
                                                name="radio-options"></b-form-radio-group>
                                        </b-col>

                                        <template v-if="selected == 'first'">
                                            <b-col lg="6" class="pl-0">
                                                <label><strong>Beneficiary Account*</strong></label><br />
                                                <b-form-select class="custom-account-statement-filter-input"
                                                    v-model="selectedBeneficiary">
                                                    <option :value="null" disabled>-- Please select an option --
                                                    </option>
                                                    <option v-for="beneficiary in beneficiaries" :value="beneficiary">
                                                        {{ beneficiary.accountNumber }}
                                                    </option>
                                                </b-form-select>
                                            </b-col>

                                            <b-col lg="6">
                                                <label><strong>Beneficiary Name</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.accountName"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Beneficiary Address</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.address"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Account Number/IBAN</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.accountNumber"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Beneficiary BIC/SWFIT CODE*</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.swiftCode"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Bank</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.bank"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Bank Address</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.bankAddress"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Branch Name</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.branch"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="12">
                                                <label><strong>Beneficiary Email</strong></label><br />
                                                <input type="text" v-model="selectedBeneficiary.email"
                                                    class="custom-account-statement-filter-input form-control" readonly>
                                            </b-col>
                                        </template>

                                        <template v-else>
                                            <b-col lg="6" class="pl-0">
                                                <label><strong>Beneficiary Account*</strong></label><br />
                                                <input type="text" v-model="selectedAccountTo"
                                                    placeholder="Enter beneficiary account number"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col lg="6">
                                                <label><strong>Beneficiary Name</strong></label><br />
                                                <input type="text" v-model="selectedAccountTo"
                                                    placeholder="Enter beneficiary account name"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Beneficiary Address</strong></label><br />
                                                <input type="text" v-model="address"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Account Number/IBAN</strong></label><br />
                                                <input type="text" v-model="accountNumber"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Beneficiary BIC/SWFIT CODE*</strong></label><br />
                                                <input type="text" v-model="swiftCode"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Bank</strong></label><br />
                                                <input type="text" v-model="bank"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="6">
                                                <label><strong>Bank Address</strong></label><br />
                                                <input type="text" v-model="bankAddress"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="mt-3" lg="6">
                                                <label><strong>Branch Name</strong></label><br />
                                                <input type="text" v-model="branch"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <b-col class="pl-0 mt-3" lg="12">
                                                <label><strong>Beneficiary Email</strong></label><br />
                                                <input type="text" v-model="email"
                                                    class="custom-account-statement-filter-input form-control">
                                            </b-col>

                                            <div class="ml-auto mt-4">
                                                <b-button class="custom-auth-signin custom-mw-50" @click="submit()">
                                                    Save Befeniciary
                                                </b-button>
                                            </div>
                                        </template>
                                    </b-row>
                                    <hr />

                                    <b-row>
                                        <b-col lg="6" class="pl-0">
                                            <label><strong>Amount*</strong></label><br />
                                            <input type="number" v-model="amount"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0">
                                            <label><strong>Currencies*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="currencies" v-model="selectedCurrency">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Execution Date*</strong></label><br />
                                            <input type="date" v-model="date"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Reference</strong></label><br />
                                            <input type="text" v-model="reference" placeholder="Enter reference"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Payment Purpose*</strong></label><br />
                                            <input type="text" v-model="purpose" placeholder="Enter purpose of payment"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="mt-3">
                                            <label><strong>Charges*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="charges" v-model="selectedCharge">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>

                                    <div class="d-flex justify-content-end mt-4">
                                        <b-button-group>
                                            <b-button class="custom-auth-signin my-1" @click="clear()">
                                                Clear
                                            </b-button>

                                            <b-button class="custom-auth-register custom-btn-group my-1 ml-1 px-4"
                                                @click="submit()">
                                                Submit
                                            </b-button>
                                        </b-button-group>
                                    </div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Swift',
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {    
        return {
            charges: ["BEN", "OUR", "SHA"],
            selectedCharge: null,
            building: null,
            street: null,
            city: null,
            selectedCountry: null,
            countries: ["Kenya", "Somalia", "Uganda"],
            id: null,
            dob: null,

            selected: 'first',
            options: [
                { text: 'Saved', value: 'first' },
                { text: 'New', value: 'second' }
            ],
            submitStatus: false,
            selectedAccount: null,
            accounts: [
                {
                    "accountNumber": "1029384 | KES 5,019",
                    "accountName": "Lee Mwangi"
                },
                {
                    "accountNumber": "9810191 | KES 900",
                    "accountName": "Lee Joint Account"
                }
            ],
            beneficiaries: [
                {
                    "accountNumber": "0091819 - John Mwangi",
                    "accountName": "John Mwangi",
                    "email": "johnmwas@gmail.com",
                    "bank": "ABSA Bank",
                    "branch": "ABSAXE901",
                    "address": "123",
                    "bankAddress": "543",
                    "swiftCode": "SWO0191P1O"
                },
                {
                    "accountNumber": "8171711 - Jane Mwangi",
                    "accountName": "Jane Mwangi",
                    "email": "janemwas@gmail.com",
                    "bank": "KCB Bank",
                    "branch": "KCBXE901",
                    "address": "123",
                    "bankAddress": "543",
                    "swiftCode": "SW9019181"
                }
            ],
            selectedBeneficiary: {},
            address: null,
            accountNumber: null,
            swiftCode: null,
            bankAddress: null,
            branch: null,

            amount: 0,
            currencies: ["SOS", "USD", "EUR", "GDP"],
            selectedCurrency: null,
            date: null,
            reference: null,
            purpose: null,
            physicalAddress: null
        }
    },

    created() {
        this.accountsTo = this.accounts;
    },

    methods: {
        removeAccount(e) {
            // this.accountsTo = this.accounts;

            // const indexToRemove = this.accountsTo.findIndex(account => account.accountName === e)

            // if (indexToRemove !== -1) {
            //     this.accountsTo.splice(indexToRemove, 1);
            // }
        },

        clear() {
            this.selectedAccount = null,
                this.selectedAccountTo = null,

                this.amount = 0,
                this.selectedCurrency = null,
                this.date = null,
                this.reference = null
            this.selected = 'first'
            this.selectedBeneficiary = {}
            this.physicalAddress = null
            this.purpose = null
        },

        submit() {
            this.submitStatus = !this.submitStatus
        }
    }
}
</script>