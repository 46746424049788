<template>
    <div class="payments">
        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Payment Details
                                </p>  

                                <p class="text-muted">
                                    Payment Reference <span class="custom-underline">#202303061157001</span>
                                </p>  

                                <b-card>                                  
                                    <b-row>
                                        <b-col class="lg-4 flex flex-column">
                                            <label class="my-0 text-muted">Account From</label>
                                            <h5 class="my-0">0190191</h5>
                                        </b-col>

                                        <b-col class="lg-4">
                                            <label class="my-0 text-muted">Account Name</label>
                                                <h5 class="my-0">Lee Mwangi</h5>
                                        </b-col>   
                                        
                                        <b-col class="lg-4">
                                            <label class="my-0 text-muted">Transaction Type</label>
                                                <h5 class="my-0">Mpesa</h5>
                                        </b-col>   
                                    </b-row><hr />

                                    <b-row>
                                        <b-col class="lg-4 flex flex-column">
                                            <label class="my-0 text-muted">Account To</label>
                                            <h5 class="my-0">0701969001</h5>
                                        </b-col>

                                        <b-col class="lg-4">
                                            <label class="my-0 text-muted">Account Name</label>
                                                <h5 class="my-0">John Doe</h5>
                                        </b-col>   
                                        
                                        <b-col class="lg-4">
                                            <label class="my-0 text-muted">Amount</label>
                                                <h5 class="my-0">KES 50,090</h5>
                                        </b-col>   
                                    </b-row><hr />

                                    <b-row>
                                        <b-col class="lg-4 flex flex-column">
                                            <label class="my-0 text-muted">Transaction Date</label>
                                            <h5 class="my-0">2023-03-06 - 11:34</h5>
                                        </b-col>   
                                    </b-row>

                                    <div class="d-flex justify-content-end mt-4">
                                        <b-button-group size="sm">
                                            <b-button class="custom-auth-signin my-1" @click="back()">
                                                Back
                                            </b-button>

                                            <b-button class="custom-auth-register custom-btn-group my-1 ml-1 px-4"
                                                @click="submit()">
                                                Authorize
                                            </b-button>
                                        </b-button-group>
                                    </div>
                                </b-card>
                                
                            </b-card>
                        </div>
                    </div>

                    <img class="custom-desktop" src="../../assets/img/background-7.png" width="100%" />
                </div>
            </div>
        </div>
        <Footer />

        <b-sidebar v-model="sidebar" backdrop right no-header width="50%">
            <div class="p-3">
                <h4>Payment Details</h4>   
                
                <b-card class="mt-4">
                    <b-row class="">
                        <b-col class="lg-6 flex flex-column">
                            <label class="my-0 text-muted">Account From</label>
                            <h5 class="my-0">0190191</h5>
                        </b-col>

                        <b-col class="lg-6">
                            <label class="my-0 text-muted">Account Name</label>
                                <h5 class="my-0">Lee Mwangi</h5>
                        </b-col>                        
                    </b-row>
                </b-card>
            </div>
             
        </b-sidebar>
    </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: "PendingPayments",
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
        }
    },

    methods: {
        back() {
            this.$router.push('pending')
        },

        submit() {
            this.showSuccess("Payment authorized successfully")
            this.$router.push('pending')
        }
    }
}
</script>