<template>
  <div id="app">    
      <router-view></router-view>
    </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
import { EventBus } from '@/main';

  export default {
    name: 'App',
    data() {
      return {
        timer: null
      }
    },
    
    created() {
      this.authenticateUser()
      this.checkLoginStatus()

      EventBus.$on('trackLoginTimer', () => {
        this.checkLoginStatus()
      });
    },

    mounted() {
      EventBus.$on('logoutUser', () => {
        this.userLogout()
      });
    },

    methods: {
      ...mapMutations([
        'setUserDetails',
        'setNationalID',
        'setClientAccounts',
        'setClientID',
        'logout'
      ]),

      authenticateUser() {
        if(localStorage.getItem("userDetails")) {
          this.setUserDetails(JSON.parse(localStorage.getItem("userDetails")))
        }

        if(localStorage.getItem("nationalID")) {
          this.setNationalID(localStorage.getItem("nationalID"))
        }

        if(localStorage.getItem("clientAccounts")) {
          this.setClientAccounts(JSON.parse(localStorage.getItem("clientAccounts")))
        }

        if(localStorage.getItem("clientID")) {
          this.setClientID(localStorage.getItem("clientID"))
        }
      },

      checkLoginStatus() {
        clearInterval(this.timer)

        if(localStorage.getItem("loginTime")) {
          this.timer = setInterval(() => {
            this.trackLoginTime();
          }, 60000);
        }
      },

      trackLoginTime() {
        let loginTime = localStorage.getItem("loginTime")
        let currentTime = new Date()

        let timeDifference = currentTime.getTime() - Date.parse(loginTime)

        if (timeDifference > 60 * 60 * 1000) {
          EventBus.$emit('logoutUser')
          clearInterval(this.timer)
        }
      },

      userLogout() {
        this.logout()
        localStorage.clear()
        this.$router.push("/login")
      }
    }
  }
</script>
<style>
  @import './assets/css/style.css';
  @import './assets/css/sb-admin-2.css';
  @import './assets/css/responsive.css';
  @import 'https://unpkg.com/bootstrap-vue@2.16.0/dist/bootstrap-vue.css';
  @import 'https://unpkg.com/bootstrap@4.5.2/dist/css/bootstrap.min.css';
</style>  