<template>
    <div class="register">
        <TopNav />

        <div id="wrapper">
            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">

                    <div class="row">
                        <div class="col-lg-6 p-0">
                            <img src="../../assets/img/background-12.png" width="100%" />
                        </div>

                        <div class="custom-register-container-1 col-lg-6 p-4">
                            <div class="row custom-register-container-2">
                                <div class="col-1">
                                    <font-awesome-icon class="custom-register-icon" icon="fa-angle-left"
                                        @click="navigateRegister()" />
                                </div>
                                <div class="col-10">
                                    <p class="custom-auth-subtitle m-0">Account Password</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-1"></div>

                                <div class="col-lg-6">
                                    <small class="text-black">Set up your account password:</small>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-lg-1"></div>

                                <div class="col-lg-8">
                                    <label class="text-black m-0">Password:</label>
                                    <b-form-input type="password" class="custom-auth-input" placeholder="Enter password"
                                        v-model="password"></b-form-input>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-lg-1"></div>

                                <div class="col-lg-8">
                                    <label class="text-black m-0">Confirm Password:</label>
                                    <b-form-input type="password" class="custom-auth-input"
                                        placeholder="Enter password confirmation" v-model="confirm_password"></b-form-input>
                                </div>
                            </div>

                            <div class="row mt-2" v-if="password != null">
                                <div class="col-lg-1"></div>

                                <div class="col-lg-6">
                                    <small class="text-black">Password strength: </small>
                                </div>                               
                            </div>

                            <div class="row" v-if="passwordStrength === 'weak'">
                                <div class="col-lg-1"></div>

                                <div class="col-2">
                                    <hr class="custom-border-weak mt-2" />
                                </div>
                                <small class="text-black">Weak</small>
                            </div>

                            <div class="row" v-if="passwordStrength === 'medium'">
                                <div class="col-lg-1"></div>

                                <div class="col-4">
                                    <hr class="custom-border-medium mt-2" />
                                </div>
                                <small class="text-black">Medium</small>
                            </div>

                            <div class="row" v-if="passwordStrength === 'strong'">
                                <div class="col-lg-1"></div>

                                <div class="col-6">
                                    <hr class="custom-border-strong mt-2" />
                                </div>
                                <small class="text-black">Strong</small>
                            </div>

                            <div class="row mt-4">
                                <div class="col-lg-1"></div>

                                <div class="col-lg-6">
                                    <b-button class="custom-auth-signin" @click="setPassword()">
                                        NEXT
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row custom-register-container-3 p-4 shadow">
                        <div class="col-lg-6">
                            <p class="text-black m-0">
                                Making your life smarter and easier to manage your cash and life everyday, everywhere
                            </p>
                        </div>
                    </div>

                    <div class="row custom-auth-container-2 p-4 shadow">
                        <div class="col-lg-6">
                            <p class="custom-auth-subtitle m-0">
                                IBS helps you enjoy the convenience of banking and access loans at the comfort of your
                                home or at
                                go!
                            </p>
                        </div>
                        <div class="col-lg-6 px-4">
                            <small class="text-black">
                                If you are having issues in registration or login to Online Banking service, please contact
                                our contact
                                center or write to us.
                            </small>

                            <div class="row mt-4">
                                <div class="col-6">
                                    <small class="custom-auth-heading">Phone Number</small>
                                </div>
                                <div class="col-6">
                                    <small class="custom-auth-heading">Email</small>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <small class="text-black">+254 729 333 444</small>
                                </div>
                                <div class="col-6">
                                    <small class="text-black">info@ibsbank.co.ke</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'RegisterView',
    components: {
        SideNav,
        TopNav,
        Footer
    },
    computed: {
        ...mapGetters(['getRegistrationData']),

        passwordStrength() {
            if(this.password != null && this.password != '') {
                let passwordLength = this.password.length;
                let uppercaseRegex = /[A-Z]/;
                let lowercaseRegex = /[a-z]/;
                let numberRegex = /\d/;
                let specialCharRegex = /[$-/:-?{-~!"^_`\[\]]/;
                let strengthLevel = '';

                if (passwordLength < 8) {
                    strengthLevel = 'weak';
                } 
                else if (passwordLength >= 8 && passwordLength <= 10) {
                    if (uppercaseRegex.test(this.password) && lowercaseRegex.test(this.password) && numberRegex.test(this.password)) {
                        strengthLevel = 'medium';
                    } else {
                        strengthLevel = 'weak';
                    }
                } 
                else if (passwordLength > 10) {
                    if (uppercaseRegex.test(this.password) && lowercaseRegex.test(this.password) && numberRegex.test(this.password) && specialCharRegex.test(this.password)) {
                        strengthLevel = 'strong';
                    } else {
                        strengthLevel = 'medium';
                    }
                }

                return strengthLevel;
            }            
        }
    },

    data() {
        return {
            strength: null,
            password: null,
            confirm_password: null
        }
    },
    methods: {
        navigateRegister() {
            this.$router.push("register")
        },

        setPassword() {
            let that = this

            if (that.password != null && that.confirm_password != null) {
                if (that.password === that.confirm_password) {
                    let data = that.getRegistrationData
                    data.password = that.password
                    that.$router.push("security-questions")
                }
                else {
                    that.showError("Passwords do not match")
                }
            }
            else {
                that.showError("Password fields cannot be empty")
            }
        },

        verifyRegistrationData() {
            let that = this

            // if(that.getRegistrationData == null) {
            //   this.$router.push("register")
            // }
        }
    },

    created() {
        this.verifyRegistrationData();
    },
}
</script>
  