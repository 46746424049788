<template>
    <div class="transfer">
        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <b-row>
                        <b-col lg="12">
                            <b-card>
                                <template v-if="submitStatus">
                                    <div class="d-flex justify-content-center mt-4">
                                        <div class="d-flex flex-column custom-mw-400">
                                            <img class="mx-auto" src="@/assets/img/success.png" width="80px">
                                            <p class="text-center custom-subtitle-text-bold-1 mt-2 mb-0">Transaction
                                                Initiated Successfully!</p>
                                            <p class="text-center custom-small-text-1 mt-2">Your transaction has been
                                                initiated successfully. The transaction shall be completed within the
                                                next 2-3 hours.

                                            </p>
                                            <p class="text-center custom-small-text-1">
                                                Thank you
                                            </p>

                                            <b-button class="custom-logout-logout mx-auto custom-mw-100 px-4"
                                                @click="submit()">
                                                Return
                                            </b-button>
                                        </div>

                                    </div>

                                </template>

                                <template v-else>
                                    <p class="custom-account-statement-title">
                                        Between My Accounts
                                    </p>

                                    <b-row class="mt-4">
                                        <b-col class="pl-0">
                                            <label><strong>From Account*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="accounts" @change="removeAccount($event)"
                                                text-field="accountNumber" value-field="accountName"
                                                v-model="selectedAccount">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col>
                                            <label><strong>Account Name</strong></label><br />
                                            <input type="text" v-model="selectedAccount"
                                                class="custom-account-statement-filter-input form-control" readonly>
                                        </b-col>
                                    </b-row>
                                    <hr />

                                    <b-row class="mt-2">
                                        <b-col class="pl-0">
                                            <label><strong>To Account*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="accountsTo" text-field="accountNumber"
                                                value-field="accountName" v-model="selectedAccountTo">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col>
                                            <label><strong>Account Name</strong></label><br />
                                            <input type="text" v-model="selectedAccountTo"
                                                class="custom-account-statement-filter-input form-control" readonly>
                                        </b-col>
                                    </b-row>
                                    <hr />

                                    <b-row>
                                        <b-col lg="6" class="pl-0">
                                            <label><strong>Amount*</strong></label><br />
                                            <input type="number" v-model="amount"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0">
                                            <label><strong>Currencies*</strong></label><br />
                                            <b-form-select class="custom-account-statement-filter-input"
                                                :options="currencies" v-model="selectedCurrency">
                                                <option :value="null" disabled>-- Please select an option --</option>>
                                            </b-form-select>
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Execution Date*</strong></label><br />
                                            <input type="date" v-model="date"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>

                                        <b-col lg="6" class="pl-0 mt-3">
                                            <label><strong>Reference</strong></label><br />
                                            <input type="text" v-model="reference" placeholder="Enter reference"
                                                class="custom-account-statement-filter-input form-control">
                                        </b-col>
                                    </b-row>

                                    <div class="d-flex justify-content-end mt-4">
                                        <b-button-group>
                                            <b-button class="custom-auth-signin my-1" @click="clear()">
                                                Clear
                                            </b-button>

                                            <b-button class="custom-auth-register custom-btn-group my-1 ml-1 px-4"
                                                @click="submit()">
                                                Submit
                                            </b-button>
                                        </b-button-group>
                                    </div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'LoanCalculatorView2',
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
            submitStatus: false,
            selectedAccount: null,
            accounts: [
                {
                    "accountNumber": "1029384 | KES 5,019",
                    "accountName": "Lee Mwangi"
                },
                {
                    "accountNumber": "9810191 | KES 900",
                    "accountName": "Lee Joint Account"
                }
            ],
            accountsTo: null,
            selectedAccountTo: null,

            amount: 0,
            currencies: ["SOS", "USD", "EUR", "GDP"],
            selectedCurrency: null,
            date: null,
            reference: null
        }
    },

    created() {
        this.accountsTo = this.accounts;
    },

    methods: {
        removeAccount(e) {
            // this.accountsTo = this.accounts;

            // const indexToRemove = this.accountsTo.findIndex(account => account.accountName === e)

            // if (indexToRemove !== -1) {
            //     this.accountsTo.splice(indexToRemove, 1);
            // }
        },

        clear() {
            this.selectedAccount = null,
                this.selectedAccountTo = null,

                this.amount = 0,
                this.selectedCurrency = null,
                this.date = null,
                this.reference = null
        },

        submit() {
            this.submitStatus = !this.submitStatus
        }
    }
}
</script>