<template>
    <div v-if="getSiderbar" class="side-nav">
        <ul class="navbar-nav custom-side-nav-background sidebar sidebar-dark accordion" id="accordionSidebar">
            <li class="nav-item">
                <span class="nav-link custom-nav-link" @click="navigate('/account/balance')">
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-house" />
                        </b-col>
                        <b-col lg="10" sm="12">
                            <span class="custom-nav-item custom-nav-item-mobile">Home</span>
                        </b-col>
                    </b-row>
                </span>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-1>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-user" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Your Accounts</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!accountsCollapse" icon="fa-solid fa-angle-right"
                                class="custom-side-nav-icon  custom-angle-icon" />
                            <font-awesome-icon v-if="accountsCollapse" icon="fa-solid fa-angle-down"
                                class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>
                </span>
                <b-collapse id="collapse-1" class="collapse mt-2" v-model="accountsCollapse">
                    <b-card no-body class="collapse-inner rounded p-0">
                        <span class="custom-nav-link collapse-item" @click="navigate('/account')"><span
                                class="custom-hyphen">-</span> Member Info</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/account/current')"><span
                                class="custom-hyphen">-</span> Current Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/account/loan')"><span
                                class="custom-hyphen">-</span> Loan Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/account/investa')"><span
                                class="custom-hyphen">-</span> Investa Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/account/shares')"><span
                                class="custom-hyphen">-</span> Shares Accounts</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-2>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-sack-dollar" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Loans</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!loansCollapse" icon="fa-solid fa-angle-right"
                                class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="loansCollapse" icon="fa-solid fa-angle-down"
                                class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>
                </span>
                <b-collapse id="collapse-2" class="collapse mt-2" v-model="loansCollapse">
                    <b-card no-body class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item" @click="navigate('/loan-calculator')"><span
                                class="custom-hyphen">-</span> Loan Calculator</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/loan-applications')"><span
                                class="custom-hyphen">-</span> Loan Applications</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-3>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-file" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Guarantorship</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!guarantorCollapse" icon="fa-solid fa-angle-right"
                                class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="guarantorCollapse" icon="fa-solid fa-angle-down"
                                class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>
                </span>
                <b-collapse id="collapse-3" class="collapse mt-2" v-model="guarantorCollapse">
                    <b-card no-body class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item" @click="navigate('/loan-guarantors')"><span
                                class="custom-hyphen">-</span> Loan Guarantors</span>
                        <span class="custom-nav-link collapse-item" @click="navigate('/loan-guarantees')"><span
                                class="custom-hyphen">-</span> Loan Guarantees</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-4>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-money-bill-transfer" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Transfer</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!transferCollapse" icon="fa-solid fa-angle-right"
                                class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="transferCollapse" icon="fa-solid fa-angle-down"
                                class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>
                </span>

                <b-collapse id="collapse-4" class="collapse mt-2" v-model="transferCollapse">
                    <b-card no-body class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item"
                            @click="navigate('/transfer/between-accounts')"><span class="custom-hyphen">-</span> Between
                            My Accounts</span>

                        <span class="custom-nav-link collapse-item"
                            @click="navigate('/transfer/between-ibs-accounts')"><span class="custom-hyphen">-</span>
                            Between IBS Accounts</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/transfer/local-eft')"><span
                                class="custom-hyphen">-</span> Local EFT</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/transfer/local-rtgs')"><span
                                class="custom-hyphen">-</span> Local RTGS</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/transfer/mobile-money')"><span
                                class="custom-hyphen">-</span> Mobile Money</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/transfer/swift')"><span
                                class="custom-hyphen">-</span> Swift Transfer</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/transfer/eaps')"><span
                                class="custom-hyphen">-</span> EAPS Transfer</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/standing-order')"><span
                                class="custom-hyphen">-</span> Standing Order</span>

                        <span class="custom-nav-link collapse-item" @click="navigate('/beneficiary-management')"><span
                                class="custom-hyphen">-</span> Beneficiary Mgmt.</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-5>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-list-check" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Payments</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!paymentsCollapse" icon="fa-solid fa-angle-right"
                                class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="paymentsCollapse" icon="fa-solid fa-angle-down"
                                class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>
                </span>

                <b-collapse id="collapse-5" class="collapse mt-2" v-model="paymentsCollapse">
                    <b-card no-body class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item" @click="navigate('/payments/pending')"><span
                                class="custom-hyphen">-</span> Pending Payments</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item" @click="navigate('/customer-care')">
                <span class="custom-nav-link nav-link">
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-headset" />
                        </b-col>
                        <b-col lg="10" sm="12">
                            <span class="custom-nav-item">Customer Care</span>
                        </b-col>
                    </b-row>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'SideNav',
    computed: {
        ...mapGetters(['getSiderbar'])
    },

    data() {
        return {
            accountsCollapse: false,
            loansCollapse: false,
            guarantorCollapse: false,
            transferCollapse: false,
            paymentsCollapse: false
        }
    },

    methods: {
        navigate(path) {
            this.$router.push(path)
        }
    }
}
</script>