<template>
    <div class="footer">
        <div class="custom-footer row p-4">
            <p class="col-lg-12 custom-footer-text pl-4">
                &#169; {{date}} IBS Bank
                &emsp;
                &emsp;
                <router-link class="custom-underline" to="/terms">Terms & Conditions </router-link>                
                &emsp;
                &emsp;
                <router-link class="custom-underline" to="/terms">Privacy Policy</router-link>                
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                date: new Date().getFullYear()
            }
        },
    }
</script>