import axios from 'axios'
import router from '@/router/index'
import { EventBus } from '@/main';

let APIM = function() {
  let session = true 

  let baseAPI  = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    auth: {
      username: process.env.VUE_APP_USERNAME,
      password: process.env.VUE_APP_PASSWORD,
    }
  })

  baseAPI.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {       
        if(router.currentRoute.path != "/otp-verification") {
          EventBus.$emit('logoutUser')
        }        
      }
      return Promise.reject(error)
    }
  )

  return baseAPI
}

export default APIM; 