<template>
  <Doughnut v-if="summary != null" :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'Chart',
  components: { Doughnut },
  props: {
    summary: null,
  },
  
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  created() {
    let lb = [ 'CURRENT ACCOUNT', 'LOANS', 'INVESTA', 'SHARES' ]
    let ds = [
          {
            backgroundColor: ['#B84176', '#41B883', '#00AFCF', '#CF2000'],
            data: [this.summary.savings, this.summary.loanBalance, this.summary.deposits, this.summary.shareCapital]
          }]

    this.chartData = {
        labels: lb,
        datasets: ds
    }     
  },
}
</script>