<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <img class="custom-desktop" src="../../assets/img/background-9.png" width="100%" />

                    <b-card class="custom-logout-container">
                        <p class="custom-logout-title">Are you sure you want to logout?</p>

                        <div class="row">                            
                            <div class="col-4 mr-2">
                                <b-button class="custom-logout-cancel" @click="navigateAccount()">CANCEL</b-button>
                            </div>
                            <div class="col-4">
                                <b-button class="custom-logout-logout" @click="navigateLogin()">LOGOUT</b-button>
                            </div>
                        </div><hr />

                        <div class="row mt-4">
                            <div class="col-lg-2">
                                <p class="custom-account-subtitle">Focus on growth with flexible funding</p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Quick <br /> Application</p>
                                <p class="text-black">
                                    Apply online in minutes and connect your business accounts, and we'll look at your financial
                                    info in real time to give you a decision.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Flexible <br />Access</p>
                                <p class="text-black">
                                    Use what you need when you need it - with affordable interest rates.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Term Length <br />Options</p>
                                <p class="text-black">
                                    15-, 30-, or 45-days loan term options with flexible timely fees.
                                </p>
                            </div>

                            <div class="col-lg-2">
                                <p class="custom-account-subtitle-2">Straightforward <br /> Fees</p>
                                <p class="text-black">
                                    No hidden extra charges. Calculate your loan repayment fee before applying
                                </p>
                            </div>
                        </div>
                    </b-card>
                    
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'LogoutView',
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
            //
        }

    },

    methods: {
        navigateAccount() {
            this.$router.push("account")
        },

        navigateLogin() {
            this.$router.push("login")
        },
    }
}
</script>
  