<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">                  
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Loan Applications
                                </p>
                                <b-table striped hover :fields="fields" :items="loanApplications" bordered :busy="isBusy" show-empty responsive> 
                                    <template #cell(loanAmount)="row">
                                        <span>{{ row.item.loanAmount.toLocaleString() }}</span>
                                    </template> 
                                    <template #cell(loanStatus)="row">
                                        <template v-if="row.item.loanStatus == 'Loan Rejected'">
                                            <label class="text-danger font-weight-bold">{{row.item.loanStatus}}</label>
                                        </template>    
                                        <template v-else-if="row.item.loanStatus == 'Loan Disbursed'">
                                            <label class="text-success font-weight-bold">{{row.item.loanStatus}}</label>
                                        </template>   
                                        <template v-else>
                                            <span class="font-weight-bold">{{row.item.loanStatus}}</span>
                                        </template>
                                    </template> 

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong> Loading...</strong>
                                        </div>
                                    </template> 

                                    <template #empty="scope">
                                        <p>No data</p>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>                        
                    </div>

                    <img class="custom-desktop" src="../../assets/img/background-7.png" width="100%" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import api from "../../apis/api";
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'LoanApplicationsView',
    components: {
        SideNav,
        TopNav,
        Footer
    },

    computed: {
        ...mapGetters(['getPhoneNumber'])
    },
    
    data() {
        return {
            fields: [
                "applicationID",
                "productName",
                "loanAmount",
                "applicationDate",
                "loanStatus",
            ],

            loanApplications: null,
            isBusy: true
        }
    },

    created() {
        this.fetchLoanApplications();
    },

    methods: {
        fetchLoanApplications() {
            let that = this

            api.applications(that.getPhoneNumber)
                .then((response) => {
                    that.loanApplications = response.data.details
                    that.isBusy = false
                })
                .catch(function (error) {
                    that.showError(error.response.data.message)
                    that.isBusy = false
                });
        }
    }
}
</script>
  