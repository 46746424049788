<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="px-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="custom-account-balance-title">
                                    Welcome. You have successfully registered for IBS Member Access Portal service. Below
                                    is summary of your account
                                </p>
                            </div>
                        </div>

                        <div v-if="isLoading" class="custom-spinner">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>

                        <template v-if="!isLoading">
                            <div class="row my-4">
                                <div class="col-lg-3">
                                    <b-card header="CURRENT ACCOUNT">
                                        <b-card-text>
                                            <p class="custom-account-balance-text" v-if="Object.keys(summary).length != 0">
                                                KSH. {{ summary.savings.toLocaleString() }}
                                            </p>
                                        </b-card-text>
                                    </b-card>
                                </div>

                                <div class="col-lg-3">
                                    <b-card header="LOANS">
                                        <b-card-text>
                                            <p class="custom-account-balance-text" v-if="Object.keys(summary).length != 0">
                                                KSH. {{ summary.loanBalance.toLocaleString() }}
                                            </p>
                                        </b-card-text>
                                    </b-card>
                                </div>

                                <div class="col-lg-3">
                                    <b-card header="INVESTA">
                                        <b-card-text>
                                            <p class="custom-account-balance-text" v-if="Object.keys(summary).length != 0">
                                                KSH. {{ summary.deposits.toLocaleString() }}
                                            </p>
                                        </b-card-text>
                                    </b-card>
                                </div>

                                <div class="col-lg-3">
                                    <b-card header="SHARE CAPITAL">
                                        <b-card-text>
                                            <p class="custom-account-balance-text" v-if="Object.keys(summary).length != 0">
                                                KSH. {{ summary.shareCapital.toLocaleString() }}
                                            </p>
                                        </b-card-text>
                                    </b-card>
                                </div>
                            </div>

                            <div class="row mb-4">
                                <div class="col-lg-6">
                                    <b-card header="PERFORMANCE SUMMARY">
                                        <label><strong>Select Account</strong></label><br />
                                        <b-input-group>
                                            <b-form-select v-model="selectedProduct" class="custom-account-statement-filter-input">
                                            <option v-for="option in accountNumbers" :value="option" :selected="true">
                                                {{ option.accountID }}
                                            </option>
                                        </b-form-select>
                                            <b-input-group-append>
                                                <b-button class="custom-auth-signin btn-sm" @click="fetchChartData()">
                                                    <b-spinner class="custom-spinner" label="Spinning" v-if="btnLoader" small></b-spinner>
                                                    <span v-if="!btnLoader">FILTER</span>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>

                                        <template v-if="lineGraphComponent">
                                            <LineGraph :lineGraphData="lineGraphData" :selectedProduct="selectedProduct" class="mt-2"/>       
                                        </template>  

                                        <template v-else>
                                            <span class="custom-small-text mt-2">No data</span>
                                        </template>                               
                                    </b-card>

                                </div>
                                <div class="col-lg-6">
                                    <b-card header="PERFORMANCE SUMMARY">
                                        <Chart :summary="summary" />
                                    </b-card>
                                </div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import api from "../../apis/api"
import { mapGetters, mapMutations } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Chart from '@/components/Chart.vue'
import LineGraph from '@/components/LineGraph.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'AccountBalance',
    components: {
        SideNav,
        TopNav,
        Footer,
        Chart,
        LineGraph
    },
    data() {
        return {
            summary: {},
            isLoading: true,

            lineGraphData: {},
            lineGraphComponent: false,
            btnLoader: false,

            accountNumbers: [],
            selectedProduct: null,
            colorCode: ''
        }
    },
    computed: {
        ...mapGetters(['getAccounts'])
    },

    created() {
        this.fetchSummary()
        this.fetchAccounts()
    },

    methods: {
        ...mapMutations([
            'setClientID'
        ]),

        fetchSummary() {
            let that = this

            try {
                api.summary()
                .then((response) => {
                    that.summary = response.data.details[0]
                    that.setClientID(that.summary.clientID)
                    localStorage.setItem("clientID", that.summary.clientID)

                    that.isLoading = false
                })
                .catch(function (error) {
                    that.showError(error.response.data.message);
                })
            }
            catch {
                that.showError("Unable to ftch records")
            }            
        },

        fetchAccounts() {
            let that = this

            that.getAccounts.map(function (item) {
                let account = {
                    accountID: item.accountID + " - " + item.productID,
                    account: item.accountID,
                    product: item.productID,
                }

                if(item.productType == "SHARES") {
                    account.colorCode = '#CF2000'
                    account.productType = 'SHARES'
                }
                else if(item.productType == "DEPOSITS") {
                    account.colorCode = '#00AFCF'
                    account.productType = 'INVESTA'
                }
                else if(item.productType == "LOANS") {
                    account.colorCode = '#41B883'
                    account.productType = 'LOANS'
                }
                else if(item.productType == "SAVINGS") {
                    account.colorCode = '#B84176'
                    account.productType = 'CURRENT ACCOUNT'
                }                

                that.accountNumbers.push(account)
            });

            if(that.accountNumbers.length) {
                that.selectedProduct = that.accountNumbers[0]
                that.fetchChartData()
            }           
        },

        fetchChartData() {
            let that = this

            if(that.unmountComponent()) {
                let data = {}
                data.product = that.selectedProduct.product

                that.btnLoader = true

                api.chart(data)
                    .then((response) => {
                        that.btnLoader = false
                        that.lineGraphData = response.data.details
                        that.mountComponent()
                    })
                    .catch(function (error) {
                        that.btnLoader = false
                        that.showError(error.response.data.message);
                    });
            }
        },

        mountComponent() {
            let that = this

            if(that.lineGraphData.length) {
                that.lineGraphComponent = true
            }
            else {
                that.lineGraphComponent = false
           }
        },

        unmountComponent() {
            let that = this

            that.lineGraphData = {}
            that.lineGraphComponent = false
            return true
        }
    }
}
</script>
