export default {
    methods: {
        showSuccess(content) {
            this.$toasted.success(content, {
                duration: 5000,
                position: 'top-center',
                action: {
                  text: 'Close',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
            });
        },

        showError(content) {
            this.$toasted.error(content, {
                duration: 5000,
                position: 'top-center',
                action: {
                  text: 'Close',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
            });
        }
    }
};
