<template>
    <div class="payments">
        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Pending Payments
                                </p>
                                <b-table striped hover :fields="fields" :items="payments" bordered :busy="isBusy"
                                    show-empty responsive>
                                    <template #cell(action)="row">
                                        <!-- <b-dropdown text="View Details" size="sm" variant='link'>
                                            <b-dropdown-item @click="navigateChangePassword()">Change Password</b-dropdown-item>
                                            <b-dropdown-item @click="navigateLogout()">Logout</b-dropdown-item>
                                        </b-dropdown>                                        -->
                                        <label class="text-primary custom-underline" size="sm"
                                            @click="showSidebar(row.item)">View Details</label>
                                    </template>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong> Loading...</strong>
                                        </div>
                                    </template>

                                    <template #empty="scope">
                                        <p>No data</p>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </div>

                    <img class="custom-desktop" src="../../assets/img/background-7.png" width="100%" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: "PendingPayments",
    components: {
        SideNav,
        TopNav,
        Footer,
    },
    data() {
        return {
            sidebar: false,
            fields: ["refNumber", "type", "date", "amount", "action"],
            payments: [
                {
                    "refNumber": "#230306001",
                    "type": "Mobile Money Transfer",
                    "amount": "KES 103,000",
                    "date": "2024-03-06",
                },
                {
                    "refNumber": "#230306002",
                    "type": "Swift Transfer",
                    "amount": "KES 10,000",
                    "date": "2024-03-06",
                },
                {
                    "refNumber": "#230306003",
                    "type": "Transfer Between Accounts",
                    "amount": "KES 56,000",
                    "date": "2024-03-06",
                }
            ]
        }
    },

    methods: {
        showSidebar() {
            this.$router.push('details')
        }
    }
}
</script>